@import '../styles/colours.scss';

.pages {
  width: 100%;

  &.container {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.transaction {
      @media screen and (max-height: 760px) {
        min-height: calc(56vmax + 1vh);
      }
    }

    .container--left {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      background-color: $app_ash;
      width: 100%;

      .tabs.dashboard {
        position: sticky;
        top: 0;
        z-index: 9;

        .MuiIconButton-root {
          width: auto;
        }
      }

      .dashboard--header-links {
        .MuiTabs-flexContainer {
          flex-wrap: wrap;
          justify-content: flex-end;

          // @media screen and (max-width: 998px) {
          //   justify-content: flex-start;
          // }
        }
      }

      .settings {
        &.sub-header {
          padding: 1rem;
          color: grey;
        }
      }
    }

    .container--right {
      flex: 0 0 270px;
      z-index: 3;

      @media screen and (max-width: 1199px) {
        display: none;
      }
    }

    .container--sidebar-open {
      margin-right: 250px;
      transition: margin-right 225ms;
    }

    .card .card--panel.MuiPaper-rounded {
      height: 10rem;
    }

    .section--card .content--list .content--item:last-child {
      margin-bottom: 0.5rem;
    }

    .container-dashboard {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 55rem;
      margin: 0 auto;
      padding: 2rem 1rem;

      .MuiPaper-rounded {
        border-radius: 7px;
      }
    }

    .account-list--no-account {
      .link {
        font-size: 16px;
        margin-left: 4px;
      }
    }
  }

  &.maintenance {
    padding: 30px;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background: $app_grey2;

    .maintenance-logo {
      height: 34px;
    }

    .maintenance-img {
      width: 150px;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  &.digital-wealth {
    .container--left {
      background: $app_white;
    }
  }

  @media only screen and (max-width: 769px) {
    .no-account-list {
      margin: 80px 32px 40px;
      text-align: center;
    }
  }

  @media only screen and (min-width: 770px) and (max-width: 1200px) {
    .no-account-list {
      margin: 160px 283px 80px;
      text-align: center;
    }
  }

  @media only screen and (min-width: 1201px) {
    .no-account-list {
      margin: 189px 192px 94.5px;
      text-align: center;
    }
  }
}

.sidebar {
  background: $app_black;
  color: $app_white;

  .nav--list {
    .MuiTabs-flexContainer {
      justify-content: space-around;
    }

    .MuiTab-root {
      line-height: 1.1;
      flex: 0;
      min-width: 100px;

      &:nth-child(2) {
        min-width: 100px;
      }

      .MuiTab-wrapper {
        padding-top: 0 !important;
        height: 38px;
        justify-content: space-between;

        &:hover {
          color: $app_gold;
        }

        > *:first-child {
          margin-bottom: 0;
        }

        svg {
          height: 20px;
        }

        img {
          height: 18px;
        }

        .MuiBadge-dot {
          top: 3px;
          right: 3px;
        }
      }
    }
  }

  .donation--actions {
    margin-top: -100px;

    p {
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .announcement {
    .card--banner-info {
      max-width: none;
    }
  }

  .dashboard--transaction {
    .MuiIconButton-label:hover {
      img {
        content: url('../assets/icons/radio-selected-light.svg');
      }
    }
  }
}

.settings-container {
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  padding: 2rem 1rem;

  @media (max-width: 900px) {
    .action-menu {
      float: right;
      width: 234px;
    }

    .action-content {
      width: 100%;
    }
  }

  .MuiPaper-rounded {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .change-password-container {

    @media screen and (max-width: 425px) {
      img {
        width: 70%;
      }
    }

    .MuiIconButton-root {
      padding: 0;
    }

    .MuiList-root {
      .MuiListItem-root {
        padding: 0 1rem;

        .MuiListItemIcon-root {
          min-width: 30px;

          .MuiSvgIcon-root {
            font-size: 1rem;
            &.dot {
              font-size: 0.5rem;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      .password-label {
        padding-bottom: 0.5rem;
      }
    }
  }
}

.inboxs-container {

  @media only screen and (max-width: 570px) {
    .MuiCardHeader-root {
      display: block !important;
    }

    .card.card--panel-header .MuiCardHeader-root .MuiCardHeader-action {
      margin: 0;
    }

    .makeStyles-root-30 {
      margin-left: 200px;
      margin-top: -35px;
    }
  }

  .MuiCardHeader-title {
    font-weight: bold !important;
    font-size: 18px !important;
  }

  .inbox--items:focus {
    background-color: #fffae6;
  }

  .content--inboxs {
    .title--inboxs {
      font-size: 15px;
    }
  }

  .detail--message {
    font-size: 14px;
  }

  .btn-text {
    .MuiButton-label {
      font-weight: bold !important;
    }
  }

  #detail-section {
    overflow-y: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $app_grey1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $app_grey2;
      border-radius: 50px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $app_grey3;
    }
  }

  .overflow-inbox-list{
    /* width */
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $app_grey1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $app_grey2;
      border-radius: 50px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $app_grey3;
    }
  }
}

.MuiButton-root.close-btn--coachmark,
.MuiButton-root.close-btn--coachmark:hover{
  color: $app_red;
  background-color: transparent;
  padding: 0;
  width: fit-content;
  margin-left: -0.75rem;
}

.MuiIconButton-root.btn--coachmark,
.MuiIconButton-root.btn--coachmark:hover{
  background-color: $app_green;
  border-radius: 50%;
  color: $app_white;
  font-size: 2rem;
  padding: 0.375rem;
  .MuiSvgIcon-root{
    font-size: 2rem;
  }
}

.coachmark--modal .MuiPaper-root{
  padding: 1rem;
  margin: 1rem;
  min-width: auto;
  display: grid;
  .MuiDialogTitle-root{
    display: none;
  }
  .MuiDialogContent-root{
    overflow: hidden;
    img{
      overflow: hidden;
      height: 100%;
    }
  }
  .MuiDialogActions-root{
    padding: 0;
    padding-top: 1rem;
  }
}

.coachmark--modal.on-loading .MuiPaper-root{
  width: 100%;
  .MuiDialogContent-root{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      overflow: hidden;
      height: 250px;
      width: 250px;
    }
  }
}
